<template>
    <v-container fluid class="pa-0 d-flex fill-height statusIQ-gradient-bg">
        <v-row class="justify-center ma-0">
            <v-col cols="12" md="10" class="mt-8 mb-12" >
                <v-row justify="center">
                    <v-img
                        class="justify-center"
                        :max-width="182.85"  
                        transition="scale-transition"
                        contain
                        src="../assets/images/siqicon.svg">
                    </v-img>
                    <!-- <v-icon transition="scale-transition" color="iq_light" class="xxxxx-large" x-large>$siqIcon</v-icon> -->
                </v-row>
            </v-col>
            <v-container>
                    <v-card rounded elevation="20" class="jusitify-center">
                        <v-row>

                            <v-col cols="12" offset="0"  md="8" offset-md="2" class="py-8">
                                <v-card-title class="justify-center ">
                                    <h1 class="text-center">Verify Your Account</h1>
                                </v-card-title>
                                <v-card-text class="justify-center text-center py-0">
                                    <h6 :class="this.$vuetify.breakpoint.smAndDown 
                                        ? 'text-h6 text-center' 
                                        : 'secondary--text text-h6'"
                                    >
                                        Enter the 6-digit code to verify your account:
                                    </h6>
                                </v-card-text>
                                <div class="ma-auto position-relative pt-6 pb-0" style="max-width: 300px">
                                    <v-otp-input
                                        v-model="otpCode"
                                        :disabled="loading"
                                        @finish="onFinish"
                                        class="primary--text"
                                        length="6"
                                        plain
                                        placeholder="0"
                                        type="number"
                                        :class=" this.$vuetify.breakpoint.smAndDown ? 'text-small': ''"
                                    ></v-otp-input>
                                    <v-overlay absolute :value="loading">
                                        <v-progress-circular
                                        indeterminate
                                        color="primary"
                                        ></v-progress-circular>
                                    </v-overlay>
                                </div>
                                <v-card-text class="justify-center text-center py-6">
                                        <a href="javascript:" @click.prevent="sendAnotherCode">Resend Code</a>
                                </v-card-text>
                                <v-card-actions>
                                    <v-row class="my-0 justify-center">
                                        <v-col cols="10" class="d-flex justify-center">
                                            <span>© {{getCurrentYear()}} - Smart Ramp</span>
                                        </v-col>
                                    </v-row>
                                </v-card-actions>
                                <v-snackbar
                                    class="snackbar-offset"
                                    v-model="snackbar"
                                    :color="snackbarColor"
                                    :timeout="3000"
                                    centered
                                >
                                    <div class="">
                                        {{ text }}
                                    </div>
                                    <template v-slot:action="{ attrs }">
                                        <v-btn
                                        text
                                        small
                                        fab
                                        v-bind="attrs"
                                        @click="snackbar = false"
                                        >
                                        <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                    </template>
                                </v-snackbar>
                                <v-snackbar
                                    class="snackbar-offset"
                                    v-model="snackbarInfo"
                                    :color="snackbarColor"
                                    :timeout="6000"
                                    centered
                                >
                                    <div class="">
                                        {{ text }} <br>
                                        {{ subtext }}
                                    </div>
                                    <template v-slot:action="{ attrs }">
                                        <v-btn
                                        text
                                        small
                                        fab
                                        v-bind="attrs"
                                        @click="snackbarInfo = false"
                                        >
                                        <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                    </template>

                                </v-snackbar>
                            </v-col>
                        </v-row>
                    </v-card>
            </v-container>
            <v-footer app>
                <v-container class="py-0">
                <v-row class="my-0 justify-center">
                    <v-col cols="12" class="d-flex justify-space-between">
                        <v-btn color="primary"  @click="goBack" outlined>Back</v-btn>
                        <v-btn color="primary" @click="onFinish" :loading="loading" :disabled="otpCode.length < 6" 
                        :class="!emailValid || this.email === null ? 'btn-disable-alt': ''"
                        >
                            <span>Verify</span>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
            </v-footer>
        </v-row>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    data () {
        return {
            loading: false,
            otpCode: "",
            snackbar: false,
            snackbarInfo: false,
            snackbarColor: 'default',
            text: '',
            subtext: '',
            sendAnotherOne: true,
            email: null,
            window: { //LAYOUT TOOL: store location when page will need vertical space calculations         )
                width: 0,
                height: 0
            },
        }
    },
    created() {
        this.emailValid = false;

        this.currentEmail 
            ? localStorage.setItem("_currentEmail", this.currentEmail) 
            : this.email = localStorage.getItem('_currentEmail');

        this.showInfoMessage();

        //LAYOUT TOOL: resize handler
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() { //LAYOUT TOOL: on leave dispose
        window.removeEventListener('resize', this.handleResize);
    },
    computed: {
        ...mapGetters({
            currentEmail: 'status/getCurrentEmail'
        })
    },
    methods: {
        goBack() {
            this.$router.push('/' + this.$route.params.id);
        },

        async onFinish () {
            this.loading = true;

            let response = await this.$store.dispatch('status/verifyOTP', {
                "otpInfo": {
                    "otpCode": this.otpCode,
                    "Url": this.$route.params.id,
                    "Email": this.currentEmail ? this.currentEmail : this.email
                }
            });

            if(response.status == 200){
                this.loading = false;
                this.$router.push('/' + this.$route.params.id + '/status');
                this.$swal({
                    icon: 'success',
                    title: '',
                    text: 'Passcode Verified.',
                    toast: true,
                    timer: 3000,
                    position: 'top-end'
                });
            }else{
                this.$swal({
                    icon: 'error',
                    title: response.data,
                    text: 'Incorrect Code! The code you have entered is not correct. Please try again.',
                });
                this.loading = false;
            }
        },

        async sendAnotherCode(event) {
            if (this.sendAnotherOne) {
                let response = await this.$store.dispatch('status/trackStatus', 
                {
                    "trackInfo": {
                        "Url": this.$route.params.id,
                        "Email": this.currentEmail
                    }
                });

                if(response){
                    this.snackbarColor = 'success'
                    this.text = `A new code has been sent!`
                    this.snackbar = true
                }else{
                    this.snackbarColor = 'error accent-2';
                    this.text = `There was an error sending the verification code. Please try again later.`
                    this.snackbar = true
                }
                event.target.style.pointerEvents = 'none';
                event.target.style.color = 'rgba(0,0,0,.26)';
                this.sendAnotherOne = false;
            }
            setTimeout(() => {
                this.sendAnotherOne = true;
                event.target.style.pointerEvents = '';
                event.target.style.color = '#f50886';
            }, 5000)
        },

        showInfoMessage() {
            this.snackbarColor = 'success'
            this.text = `Check your email address. We sent you a code for one-time use. Please enter the code to login. If you don't see your code, click resend or check spam for email.`
            this.subtext = ``
            this.snackbarInfo = true
        }
    }
}
</script>

<style lang="scss">
    .special-mt{
        margin-top: 114px
    }
    .snackbar-offset{
        top: 220px;
    }
    .v-snack__content {
        font-size: 1rem;
    }
</style>
