import Api from "@/services/api";

export default {
    namespaced: true,
    state: {
        currentTracker: null,
        currentEmail: null,
        currentTrackerInfo: null
    },
    actions: {
        saveTracker({commit},currentTracker){
            commit('SET_CURRENT_TRACKER',currentTracker);
        },
        saveEmail({commit},currentEmail){
            commit('SET_CURRENT_EMAIL',currentEmail);
        },
        saveTrackerInfo({commit},currentTrackerInfo){
            commit('SET_CURRENT_TRACKER_INFO',currentTrackerInfo);
        },
        // eslint-disable-next-line no-empty-pattern
        async verifyTrackerUrl({}, url) {
            console.log(url)
            let response = await Api().post(`/Web/SmartRamp/Status/GetTrackerCompany`, url)
            .catch((err) => {
                console.log(err);
            });
            
            return response;
        },
        async verifyEmail({commit}, {trackInfo: trackInfo}) {
            let response = await Api().post(`/Web/SmartRamp/Status/Verify`, trackInfo)
            .catch((err) => {
                console.log(err);
            });

            commit('SET_CURRENT_EMAIL',trackInfo.Email);
            return response;
        },
        async verifyOTP({commit}, {otpInfo: otpInfo}) {
            let response = await Api().post(`/Web/SmartRamp/Status/Verify/${otpInfo.otpCode}`, otpInfo)
            .catch((err) => {
                console.log(err);
                return err.response;
            });

            commit('SET_CURRENT_TRACKER_INFO', response.data);
            return response;
        },
        async trackStatus({commit}, {trackInfo: trackInfo}) {
            let response = await Api().post(`/Web/SmartRamp/Status/Verify`, trackInfo)
            .catch((err) => {
                console.log(err);
            });

            commit('SET_CURRENT_EMAIL',trackInfo.Email);
            return response;
        },

        async validateEmail({commit}, {trackInfo: trackInfo}) {
            let response = await Api().post(`/Web/SmartRamp/Status/VerifyEmail`, trackInfo)
            .catch((err) => {
                console.log(err);
            });

            commit('SET_CURRENT_EMAIL',trackInfo.Email);
            return response;
        },
    },
    mutations: {
        SET_CURRENT_TRACKER(state, currentTracker){
            state.currentTracker = currentTracker;
        },
        SET_CURRENT_EMAIL(state, currentEmail){
            state.currentEmail = currentEmail;
        },
        SET_CURRENT_TRACKER_INFO(state, currentTrackerInfo){
            state.currentTrackerInfo = currentTrackerInfo;
        },
    },
    getters: {
        getCurrentTracker: state => { return state.currentTracker},
        getCurrentEmail: state => { return state.currentEmail},
        getCurrentTrackerInfo: state => { return state.currentTrackerInfo}
    }
}
