import Vue from 'vue';
import VueRouter from 'vue-router';

import VerifyEmail from '@/views/VerifyEmail';
import VerifyOTP from '@/views/VerifyOTP';
import TrackView from '@/views/TrackView';
import NotFound from '@/views/NotFound';

// eslint-disable-next-line no-unused-vars
import { hadUrl, hadOTP } from '../auth/trackerGuard';

Vue.use(VueRouter);

const routes = [
      {
        path: '/:id',
        name: 'Status IQ Email',
        component: VerifyEmail,
        // eslint-disable-next-line no-unused-vars
        beforeEnter: hadUrl
      },
      {
        path: '/:id/verify',
        name: 'Status IQ OTP',
        component: VerifyOTP,
      },
      {
        path: '/:id/status',
        name: 'Status IQ',
        component: TrackView,
        // eslint-disable-next-line no-unused-vars
        beforeEnter: hadOTP
      },
      {
        path: '/:id/not-found',
        name: '404',
        component: NotFound,
      }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});
  
export default router;